<template>
  <div>
    <Widget
      ref="widget"
      :height="height"
      :model="model"
      :defaults="{ paramName: 'dashBoard.cash', sort: {} }"
      :data="data_table"
      @click="onClick($event)"
      :isLoading="loading"
      @loading="loading = $event"
    >
      <template v-slot:title> Остатки по кассам </template>
    </Widget>
  </div>
</template>

<script>
import { popupMenu } from "@/components/mixings";
export default {
  mixins: [popupMenu],
  components: {
    Widget: () => import("./widgets/dataTable"),
    //Widget: () => import("./widgets/sqlTable"),
  },
  props: {
    height: { type: Number, default: 0 },
  },
  data() {
    return {
      idShow: 0,
      contentHeight: 10,
      hideViewed: true,
      loading: false,
      data: [],
      showConfig: false,
      timeID: null,
      array_object: [],
      cashList: null,
      cashbox_: null,
      balanceCash: null,
      data_table: [],
    };
  },
  created() {
    this.fitchData();
  },
  computed: {
    model() {
      return [
        { name: "name", title: "Касса", type: "string", width: 120, style: "white-space: normal;" },
        { name: "date_start", title: "Дата", type: "datetime", sortable: true, width: 75, style: "white-space: normal;" },
        { name: "val_start", title: "Начало", type: "number", sortable: true, width: 80, style: "white-space: normal;" },
        { name: "val_in", title: "Приход", type: "number", sortable: true, width: 80, style: "white-space: normal;" },
        { name: "val_out", title: "Расход", type: "number", sortable: true, width: 80, style: "white-space: normal;" },
        { name: "val_end", title: "Сейчас", type: "number", formula1: "(d)=>{return d.val_start + d.val_in - d.val_out}", sortable: true, width: 80 },
      ];
    },
    sql() {
      let group = "";
      let order = "name";
      let where = ``;
      let sql = `
select *,
  (SELECT sum(value) FROM accounting_doc_order_receipt WHERE cashbox_id= cashbox.id and date_doc >=ifnull(date_start, cast(0 as datetime)) and status=2)
 -- +(SELECT sum(value) FROM accounting_doc_order_withdrawal WHERE second_cashbox_id= cashbox.id and date_doc >=ifnull(date_start, cast(0 as datetime)) and status=2) as val_in,
  (SELECT sum(value) FROM accounting_doc_order_withdrawal WHERE cashbox_id= cashbox.id and date_doc >=ifnull(date_start, cast(0 as datetime)) and status=2) as val_out
from (
  SELECT box.* , bal.*
  FROM 	accounting_dir_cashbox box
    left join (select cashbox_id, date_doc as date_start , amount as val_start from accounting_doc_cash_balance b1  where id = (select b2.id from accounting_doc_cash_balance b2 where b2.cashbox_id=b1.cashbox_id order by b2.date_doc desc limit 1)) bal on bal.cashbox_id=box.id
) as cashbox
`;
      let res = { sql, order, group, where };
      return res;
    },
    minHeight() {
      let h = this.height;
      if (this.$vuetify.breakpoint.name == "xs" && this.data.length == 0) {
        h = 0;
      }
      return h;
    },
  },
  watch: {},
  methods: {
    async getCashList() {
      const resp = await this.$axios.get("accounting/dir/cashbox/select");
      const list = [...resp.data.data];
      list.forEach((l) => {
        try {
          this.getBalance({ id: l.value, name: l.text });
        } catch (error) {}
      });
    },

    async getBalance({ id, name }) {
      if (!id) return;
      let resp;
      resp = await this.$axios.post("/report_sql", { name: "getBalanceCashBox", params: { id } });
      if (resp.data.status == "ok") {
        b = resp.data.data[0];
      }
      let b = { name: name, date_start: b?.date_doc, val_in: b?.value_in, val_out: b?.value_out, val_start: b?.value_start, val_end: b?.value_end || null };
      this.data_table.push(b);
    },
    onClick(e) {},
    itemShowClose() {},

    async fitchData() {
      this.loading = true;
      this.data_table = [];
      await this.getCashList();
      this.afterFitch();
    },
    afterFitch() {
      this.loading = false;
    },
  },
};
</script>
